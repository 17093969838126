// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-doulos-js": () => import("./../src/pages/doulos.js" /* webpackChunkName: "component---src-pages-doulos-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logos-js": () => import("./../src/pages/logos.js" /* webpackChunkName: "component---src-pages-logos-js" */),
  "component---src-pages-marriage-js": () => import("./../src/pages/marriage.js" /* webpackChunkName: "component---src-pages-marriage-js" */),
  "component---src-pages-parenting-js": () => import("./../src/pages/parenting.js" /* webpackChunkName: "component---src-pages-parenting-js" */),
  "component---src-pages-payment-js": () => import("./../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-pneuma-js": () => import("./../src/pages/pneuma.js" /* webpackChunkName: "component---src-pages-pneuma-js" */),
  "component---src-pages-portal-js": () => import("./../src/pages/portal.js" /* webpackChunkName: "component---src-pages-portal-js" */),
  "component---src-pages-selah-js": () => import("./../src/pages/selah.js" /* webpackChunkName: "component---src-pages-selah-js" */),
  "component---src-pages-signup-js": () => import("./../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-vocation-js": () => import("./../src/pages/vocation.js" /* webpackChunkName: "component---src-pages-vocation-js" */),
  "component---src-pages-work-js": () => import("./../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

